
















































































































































import { useState } from '@u3u/vue-hooks'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  name: 'custom-pagination',
  components: { CustomLink },
  props: {
    total: {
      type: Number,
      required: true,
      default() {
        return 1
      },
    },
    pageChanged: {
      type: Function,
      required: true,
      default() {
        return 1
      },
    },
    pageSize: {
      type: Number,
      required: false,
      default() {
        return 20
      },
    },
    currentPage: {
      type: Number,
      required: false,
    },
  },

  setup(props, ctx) {
    const arrayRange = stop =>
      Array.from({ length: stop }, (value, index) => 1 + index)

    const lastPageNbr = Math.ceil(props.total / props.pageSize)
    const displayLinks = ref()
    const localCurrentPage = ref(props.currentPage || 1)

    const customPageChange = nextCurrentPage => {
      if (nextCurrentPage >= 1 && nextCurrentPage <= lastPageNbr) {
        props.pageChanged({ currentPage: nextCurrentPage })
        localCurrentPage.value = nextCurrentPage
        setDisplayLinks()
      }
    }

    ctx.root.$on('reset-pagination', () => {
      customPageChange(1)
    })

    onMounted(() => {
      setDisplayLinks()
    })

    const setDisplayLinks = () => {
      displayLinks.value = arrayRange(lastPageNbr)
      if (lastPageNbr > 4) {
        const current = localCurrentPage.value
        const previous = current - 1
        const next = current + 1
        const firstPageNbr = 1

        // First page
        displayLinks.value = [firstPageNbr]

        if (previous && previous !== firstPageNbr) {
          // fillerBefore
          if (previous - 2 === firstPageNbr) {
            displayLinks.value.push(previous - 1)
          } else if (previous - 2 > firstPageNbr) {
            displayLinks.value.push('...')
          }
          // Previous page
          displayLinks.value.push(previous)
        }

        // Current Page
        if (current && current !== firstPageNbr && current !== lastPageNbr) {
          displayLinks.value.push(current)
        }

        if (next && next < lastPageNbr) {
          // Next page
          displayLinks.value.push(next)
          // fillerAfter
          if (next + 2 === lastPageNbr) {
            displayLinks.value.push(next + 1)
          } else if (next + 2 < lastPageNbr) {
            displayLinks.value.push('...')
          }
        }
        // Last page
        displayLinks.value.push(lastPageNbr)
      }
    }

    return {
      ...useState('commune', ['i18n']),
      customPageChange,
      localCurrentPage,
      lastPageNbr,
      displayLinks,
    }
  },
})
