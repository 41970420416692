




































import { useState } from '@u3u/vue-hooks'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'custom-header',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  setup(props, ctx) {
    const filtring = ref(false)
    const trier = () => {
      filtring.value = !filtring.value
      ctx.root.$emit('on-sort-change', {
        field: props.data.column.field,
        type: filtring.value ? 'asc' : 'desc',
      })
    }

    return {
      ...useState('commune', ['i18n']),
      trier,
    }
  },
})
